import React, { useState, useEffect, useContext } from "react";
import "./user.css";
import { useLocation } from "react-router-dom";
import { MdDownloadForOffline } from "react-icons/md";
import VisaContext from "../../../context/visa-context";

const UserDocuments = () => {   
   const location = useLocation();
   const travelerId = location.state.travelerId;
   const ctxApi = useContext(VisaContext);
   const applications = ctxApi.customers;

   const userApplications = applications
   .map((application) =>
     application.data.filter((datas) => datas.id === travelerId)
   )
   .flat();
 if (!userApplications) {
   return <div>No applications found for this user.</div>;
 }

  const downloadFile = async (filename) => {
    try {
      const response = await fetch(`https://backend-visa2.vercel.app/adminApi/download-file?filename=${filename}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      };  
      // Check content-type to ensure it's JSON
      const contentType = response.headers.get("content-type");
      if (!contentType || !contentType.includes("application/json")) {
        const text = await response.text();
        throw new Error(`Expected JSON, but got: ${text}`);
      }  
      const data = await response.json();
      const downloadURL = data.downloadURL;  
      const link = document.createElement("a");
      link.href = downloadURL;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };
  
  return (
    <>
      <main className="main-container">
        <div className="main-title11">
          <h3 style={{ color: "#13e313", fontSize: "25px" }}>User Document</h3>
        </div>
        <div className="userpage">
          <table>
            <thead>
              <tr>
                <th>Photo</th>
                <th>Passport</th>
                <th>Other Documents</th>
              </tr>
            </thead>
            <tbody>
              <tr>
              {userApplications.map((obj,ind)=>(
                <>
                <td key={ind}>
                  { obj.photo.map((item, ind) => (
                    <div
                      key={ind}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      className="document-fileName"
                    >
                      <p>{item.filename}</p>{" "}
                      <span>
                        <MdDownloadForOffline
                          style={{
                            marginTop: "8px",
                            fontSize: "20px",
                            marginLeft: "6px",
                          }}
                          className="downloadIcons"
                          onClick={() => downloadFile(item.originalname)}
                        />
                      </span>
                    </div>
                  ))}
                </td>

                <td key={ind}>
                  {obj.passport.map((item, ind) => (
                    <div
                     className="document-fileName"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      key={ind}
                    >
                      <p >{item.filename}</p>{" "}
                      <span>
                        <MdDownloadForOffline
                          style={{
                            marginTop: "8px",
                            fontSize: "20px",
                            marginLeft: "6px",
                          }}
                          className="downloadIcons"
                          onClick={() => downloadFile(item.originalname)}
                        />
                      </span>
                    </div>
                  ))}
                </td>

                <td key={ind}>
                  {obj.otherDocuments.map((item, ind) => (
                    <div
                     className="document-fileName"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      key={ind}                  >
                      <p >{item.filename}</p>{" "}
                      <span>
                        <MdDownloadForOffline
                          style={{
                            fontSize: "20px",
                            marginLeft: "6px",
                          }}
                          className="downloadIcons"
                          onClick={() => downloadFile(item.originalname)}
                        />
                      </span>
                    </div>
                  ))}
                </td>
                </>                
                  ))}   
              </tr>
            </tbody>
          </table>
        </div>
      </main>
    </>
  );
};

export default UserDocuments;

