import React, { useContext } from "react";
import { BsFillGrid3X3GapFill, BsPeopleFill } from "react-icons/bs";
import { Link, useLocation, useNavigate } from "react-router-dom";
import VisaContext from "../../../context/visa-context";
import { FaCcVisa } from "react-icons/fa6";

const UserDash = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userId = location?.state?.userId;
  const ctxApi = useContext(VisaContext);
  const application = ctxApi.applicatons || []; // Ensure application is an array
  const customers = ctxApi.customers || []; // Ensure customers is an array

  // Filter applications based on userId and get the count
  const userApplications = application.filter((app) => {
    return app.postData.userId === userId;
  });
  const applicationCount = userApplications.length;

  // Flatten the array of arrays and filter customers based on userId
  const filterCustomers = customers.reduce((acc, app) => {
    const matchedCustomers = app.data.filter((cust) => cust.userId === userId);
    return acc.concat(matchedCustomers);
  }, []);

  const CustomersCount = filterCustomers.length;

  const handleUserApplications = (e) => {
    e.preventDefault();
    navigate("/userapplication", { state: { userId } });
  };
  const handleClick = (e) => {
    e.preventDefault();
    navigate("/user-details", { state: { userId } });
  };
  const ApplicantHandleClick = (e) => {
    e.preventDefault();
    navigate("/worvisaData", { state: { userId } });
  };

  return (
    <main className="main-container">
      <div className="main-title">
        <h3>CUSTOMERS DASHBOARD</h3>
      </div>

      <div className="main-cards">
        <Link onClick={handleClick}>
          <div className="card" >
            <div className="card-inner">
              <h3>Tourist Visa</h3>
              <BsPeopleFill
                style={{ cursor: "pointer" }}
                className="card_icon"
              />
            </div>
            <h1>{CustomersCount}</h1>
          </div>
        </Link>
        <Link onClick={handleUserApplications}>
        <div className="card" >
          <div className="card-inner">
            <h3>APPLICATIONS</h3>
            <BsFillGrid3X3GapFill
              style={{ cursor: "pointer" }}
              className="card_icon"
            />
          </div>
          <h1>{applicationCount}</h1>
        </div>
        </Link>
        <Link onClick={ApplicantHandleClick}>
        <div className="card" >
          <div className="card-inner">
            <h3>Group Applications</h3>
            <FaCcVisa style={{ cursor: "pointer" }} className="card_icon" />
          </div>
          <h1>0</h1>
        </div>
        </Link>
      </div>
    </main>
  );
};

export default UserDash;
