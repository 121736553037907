import React, { useState, useEffect } from "react";
import VisaContext from "./visa-context";

const VisaContextProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [applicatons, setApplications] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [freeData, setFreeData] = useState([]);
  const [tmsData, setTmsData] = useState([]);
  const [tmsForm, setTmsForm] = useState([]);
  const [groupApplications, setGroupApplications] = useState([]);
  const [addOnsData, setAddOnsData] = useState([]);
  const [groupVisa, setGroupVisa] = useState([]);
  const setLoading = (value) => {
    setIsLoading(value);
  };

  const fetchUsers = async () => {
    setLoading(true); // Set loading to true before fetching
    try {
      const response = await fetch(
        "https://backend-visa2.vercel.app/adminApi/users"
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      if (Array.isArray(data)) {
        const reversedData = data.reverse();
        setUsers(reversedData); // Reverse to show latest applications first
      } else {
        console.error("Unexpected data format:", data);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };

  useEffect(() => {
    fetchUsers(); // Fetch users when the component mounts
  }, []); // Empty dependency array ensures this runs once

  // FETCH APPLICATIONS 👍

  const fetchApplications = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://backend-visa2.vercel.app/adminApi/fetch-history",
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer token",
          },
        }
      );
      if (!response.ok) {
        setLoading(false);
        throw new Error(`Error fetching applications: ${response.statusText}`);
      }
      const data = await response.json();
      setLoading(false);

      if (Array.isArray(data.data)) {
        setLoading(false);
        setApplications(data.data.reverse()); // Reverse to show latest applications first
      } else {
        console.error("Unexpected data format:", data);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching applications:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchApplications(); // Fetch users when the component mounts
  }, []); // Empty dependency array ensures this runs once

  const fetchAllCustomers = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://backend-visa2.vercel.app/adminApi/AllApplications`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("admin-token")}`,
          },
        }
      );
      if (!response.ok) {
        setLoading(false);
        throw new Error(`Error fetching applications: ${response.statusText}`);
      }
      setLoading(false);
      const data = await response.json();
      if (Array.isArray(data)) {
        setCustomers(data.reverse());
      } else {
        console.error("Unexpected data format:", data);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching applications:", error);
    }
  };
  useEffect(() => {
    fetchAllCustomers();
  }, []);

  // fetch Group visa

  const fetchAllGroupVisa = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://backend-visa2.vercel.app/adminApi/fetch-Group-Applications`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("admin-token")}`,
          },
        }
      );
      if (!response.ok) {
        setLoading(false);
        throw new Error(`Error fetching applications: ${response.statusText}`);
      }
      setLoading(false);
      const data = await response.json();
      if (Array.isArray(data)) {
        setGroupVisa(data.reverse());
      } else {
        console.error("Unexpected data format:", data);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching applications:", error);
    }
  };
  useEffect(() => {
    fetchAllGroupVisa();
  }, []);

  // ****************Freelancer Data************

  useEffect(() => {
    const fetchDataFree = async () => {
      setLoading(true);

      try {
        const response = await fetch(
          "https://backend-visa2.vercel.app/adminApi/get-FreelancerData"
        );
        if (!response.ok) {
          setLoading(false);
          throw new Error("Network response was not ok");
        }
        const datas = await response.json();
        setLoading(false);
        setFreeData(datas);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data", error);
      }
    };

    fetchDataFree();
  }, []);
  // ****************The Migration School Data************

  useEffect(() => {
    const fetchTmsData = async () => {
      setLoading(true);

      try {
        const response = await fetch(
          "https://backend-visa2.vercel.app/tmsApi/getTmsData"
        );
        if (!response.ok) {
          setLoading(false);
          throw new Error("Network response was not ok");
        }
        const datas = await response.json();
        setLoading(false);
        setTmsData(datas);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data", error);
      }
    };

    fetchTmsData();
  }, []);

  useEffect(() => {
    const fetchTmsFromData = async () => {
      setLoading(true);

      try {
        const response = await fetch(
          "https://backend-visa2.vercel.app/tmsApi/getTmsFormData"
        );
        if (!response.ok) {
          setLoading(false);
          throw new Error("Network response was not ok");
        }
        const datas = await response.json();
        setLoading(false);
        setTmsForm(datas);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data", error);
      }
    };

    fetchTmsFromData();
  }, []);

  // ****************Work Visa All Applications************

  const fetchAllGroupApplication = async () => {
    try {
      const response = await fetch(
        `https://backend-visa2.vercel.app/api/applicant/allWorkVisaApplications`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setGroupApplications(data.data);
    } catch (error) {
      console.error("Error fetching work data:", error);
    }
  };
  useEffect(() => {
    fetchAllGroupApplication();
  }, []);
  // ****************get Add Ons Data************
  useEffect(() => {
    const GetAddOnsData = async () => {
      try {
        const response = await fetch(
          `https://backend-visa2.vercel.app/adminApi/get-AddOnsData`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setAddOnsData(data);
      } catch (error) {
        console.error("Error fetching work data:", error);
      }
    };
    GetAddOnsData();
  }, []);

  const ctxValue = {
    isLoading,
    setLoading,
    fetchUsers,
    users,
    fetchApplications,
    applicatons,
    fetchAllCustomers,
    customers,
    setApplications, // Add the setApplications method
    freeData,
    tmsData,
    groupApplications,
    addOnsData,
    tmsForm,
    groupVisa,
    fetchAllGroupApplication,
  };

  return (
    <VisaContext.Provider value={ctxValue}>{children}</VisaContext.Provider>
  );
};

export default VisaContextProvider;
