import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IoEyeSharp } from "react-icons/io5";
import "../user.css";
import VisaContext from "../../../../context/visa-context";
import MyLoader from "../../../Loader/MyLoader";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { RiInformation2Line } from "react-icons/ri";
import Button from "@mui/material/Button";
import Dropdown from "react-bootstrap/Dropdown";

const Application = () => {
  const ctxApi = useContext(VisaContext);  
  const fetchApplications = ctxApi.fetchApplications;
  const navigate = useNavigate();





const handleSendDocument = (travelerId)=>{
  navigate("/userDocuments", {state:{travelerId}})
}

  // update api for status 💯 👍
  const handleStatusUpdate = async (travelerId) => {
    try {
      const response = await fetch(
        "https://backend-visa2.vercel.app/adminApi/updateUserStatus",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ travelerId: travelerId, status: "Completed" }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData = await response.json();

      // Optionally, refresh the list of travelers or update the UI to reflect the status change
      fetchApplications();
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  // HtmlTooltip style  💯
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
      padding: "10px",
    },
  }));

  return (
    <>
      <main className="main-container">
        {ctxApi.isLoading && <MyLoader />}
        <div className="main-title11">
          <h3 style={{ color: "#13e313", fontSize: "25px" }}>
            Final Informations
          </h3>
        </div>
        <div className="userpage">
          <table>
            <thead>
              <tr>
                <th>C Name</th>
                <th>Name</th>
                <th>Last Name</th>
                <th>Destination</th>
                <th>AddOns</th>
                <th>Price</th>
                <th>Selected Date</th>
                <th>Response Date</th>
                <th>Documents</th>
                <th>Time</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {ctxApi.applicatons.map((datas, ind) => (
                <tr key={ind}>
                  <td>{datas?.postData?.ClientName}</td>
                  <td>{datas.postData.name}</td>
                  <td>{datas.postData.lname}</td>
                  <td>{datas.postData.countryName}</td>
                  <td>
                    <Dropdown drop="up" className="dropdown_for_applications1">
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        AddOns
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">
                          Flight{" "}
                          <span style={{ color: "red" }}>
                            {datas?.postData?.addOnPrices?.flight !== undefined
                              ? datas.postData.addOnPrices.flight
                              : ""}
                          </span>
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-2">
                          Hotel
                          <span style={{ color: "red" }}>
                            {datas?.postData?.addOnPrices?.hotel !== undefined
                              ? datas.postData.addOnPrices.hotel
                              : ""}
                          </span>
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-3">
                          Itinerary{" "}
                          <span style={{ color: "red" }}>
                            {datas?.postData?.addOnPrices?.itinerary !==
                            undefined
                              ? datas?.postData?.addOnPrices?.itinerary
                              : ""}
                          </span>
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-3">
                          CoverLetter{" "}
                          <span style={{ color: "red" }}>
                            {" "}
                            {datas?.postData?.addOnPrices?.coverLetter !==
                            undefined
                              ? datas?.postData?.addOnPrices?.coverLetter
                              : ""}
                          </span>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                  <td>{datas.postData.countryPrice}</td>
                  <td>{datas.postData.selectedDate}</td>
                  <td>{datas.postData.futureDate}</td>
                  <td>
                    <button
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                        cursor: "pointer",
                      }}
                      onClick={() => handleSendDocument(datas.postData.travelerId)}
                    >
                      <IoEyeSharp
                        style={{ color: "white", fontSize: "21px" }}
                        className="eye_iconHover"
                      />
                    </button>
                  </td>
                  <td>{datas.postData.Time}</td>
                  <td className="status_tableData">
                    <HtmlTooltip
                      title={
                        <React.Fragment>
                          <h6>{datas.postData.status}</h6>
                          {datas.postData.status === "Processing" && (
                            <button
                              className="status_button"
                              onClick={() =>
                                handleStatusUpdate(datas.postData.travelerId)
                              }
                            >
                              Complete
                            </button>
                          )}
                        </React.Fragment>
                      }
                    >
                      <Button className="adminPannel_i_icons">
                        <RiInformation2Line />
                      </Button>
                    </HtmlTooltip>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </main>
    </>
  );
};

export default Application;
