import React, { useContext } from "react";
import VisaContext from "../../../../context/visa-context";
import MyLoader from "../../../Loader/MyLoader";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { RiInformation2Line } from "react-icons/ri";
import Button from "@mui/material/Button";
import { IoEyeSharp } from "react-icons/io5";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";

const AllWorkVisaApplications = () => {
  const ctxData = useContext(VisaContext);
  const navigate = useNavigate();
  const fetchAllGroupApplication = ctxData.fetchAllGroupApplication;

  const handleSendDocument = (travelerId)=>{
    navigate("/group-visaDoc", {state:{travelerId}})

  }

    // update api for status 💯 👍
    const handleStatusUpdate = async (travelerId) => {
      try {
        const response = await fetch(
          "https://backend-visa2.vercel.app/adminApi/update-groupVisa-Status",
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ travelerId: travelerId, status: "Completed" }),
          }
        );
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const responseData = await response.json();
  
        // Optionally, refresh the list of travelers or update the UI to reflect the status change
        fetchAllGroupApplication();
      } catch (error) {
        console.error("Error updating status:", error);
      }
    };

  // HtmlTooltip style  💯
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
      padding: "10px",
    },
  }));

  return (
    <>
      <main className="main-container">
        {ctxData.isLoading && <MyLoader />}
        <div className="main-title11">
          <h3 style={{ color: "#13e313", fontSize: "25px" }}>
            Work Visa Applications
          </h3>
        </div>
        <div className="userpage">
          <table>
            <thead>
              <tr>
                <th>C Name</th>
                <th>Name</th>
                <th>Last Name</th>
                <th>Destination</th>
                <th>AddOns</th>
                <th>Price</th>
                <th>Apply Date</th>
                <th>Documents</th>
                <th>Time</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {ctxData.groupApplications.map((item, ind) => (
                <tr key={ind}>
                  <td>{item && item.workVisa.Cname}</td>
                  <td>{item && item.workVisa.name}</td>
                  <td>{item && item.workVisa.lname}</td>
                  <td>{item && item.workVisa.countryName}</td>
                  <td>
                    <Dropdown drop="up" className="dropdown_for_applications1">
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        AddOns
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">
                          Flight{" "}
                          <span style={{ color: "red" }}>
                            {item?.workVisa?.addOnPrices?.flight !== undefined
                              ? item?.workVisa?.addOnPrices?.flight
                              : ""}
                          </span>
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-2">
                          Hotel
                          <span style={{ color: "red" }}>
                            {item?.workVisa?.addOnPrices?.hotel !== undefined
                              ? item?.workVisa?.addOnPrices?.hotel
                              : ""}
                          </span>
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-3">
                          Itinerary{" "}
                          <span style={{ color: "red" }}>
                            {item?.workVisa?.addOnPrices?.itinerary !==
                            undefined
                              ? item?.workVisa?.addOnPrices?.itinerary
                              : ""}
                          </span>
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-3">
                          CoverLetter{" "}
                          <span style={{ color: "red" }}>
                            {" "}
                            {item?.workVisa?.addOnPrices?.coverLetter !==
                            undefined
                              ? item?.workVisa?.addOnPrices?.coverLetter
                              : ""}
                          </span>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                  <td>{item && item.workVisa.countryPrice}</td>
                  <td>{item && item.workVisa.ApplyDate}</td>
                  <td>
                    <button
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handleSendDocument(item.workVisa.id)
                      }
                    >
                      <IoEyeSharp
                        style={{ color: "white", fontSize: "21px" }}
                        className="eye_iconHover"
                      />
                    </button>
                  </td>
                  <td>{item && item.workVisa.Time}</td>
                  <td className="status_tableData">
                    <HtmlTooltip
                      title={
                        <React.Fragment>
                          <h6>{item && item.workVisa.status}</h6>
                          {item.workVisa.status === "Processing" && (
                            <button
                              className="status_button"
                              onClick={() =>
                                handleStatusUpdate(item.workVisa.id)
                              }
                            >
                              Complete
                            </button>
                          )}
                        </React.Fragment>
                      }
                    >
                      <Button className="adminPannel_i_icons">
                        <RiInformation2Line />
                      </Button>
                    </HtmlTooltip>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </main>
    </>
  );
};

export default AllWorkVisaApplications;
